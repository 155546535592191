import type { ComponentBlock, PageContent } from "../components/blocks/types";

export const defineComponentBlock = <T extends ComponentBlock>(block: T): T =>
  block;

export const definePageContent = <T extends ComponentBlock[]>(
  body: T
): { content: PageContent } => ({
  content: {
    component: "page",
    body,
  },
});
